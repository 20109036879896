import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd2 = () => (
  <StaticImage
    src="../../../images/Prod/CAD-Technology-and-Rapid-Prototyping.png"
    alt="CAD designer jewelry production process"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd2
