import React from "react"
import { Flex, Box } from "rebass"

import styled from "styled-components"
import CenterH2 from "../../components/Typography/H2/CenterH2"
import WrapperContainers from "../../components/WrapperContainer"
import Ditp from "./Img/Ditp"
import Tgjta from "./Img/Tgjta"
import ThaiSilver from "./Img/ThaiSilver"

const ProductionEnd = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1]} p={[3, 2, 4]}>
        <CenterH2>We are members of following organizations: </CenterH2>
      </Box>
      <Box width={[1, 1, 1 / 3]} p={[3, 2, 4]}>
        <ImgWrapper>
          <Ditp />
        </ImgWrapper>
      </Box>
      <Box width={[1, 1, 1 / 3]} p={[3, 2, 4]}>
        <ImgBorder>
          <ImgWrapperThaiSilver>
            <ThaiSilver />
          </ImgWrapperThaiSilver>
        </ImgBorder>
      </Box>
      <Box width={[1, 1, 1 / 3]} p={[3, 2, 4]}>
        <ImgWrapperTgjta>
          <Tgjta />
        </ImgWrapperTgjta>
      </Box>
    </Flex>
  </Section>
)
export default ProductionEnd

const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const ImgBorder = styled.div`
  border-right: 1px solid ${({ theme }) => theme.text};
  border-left: 1px solid ${({ theme }) => theme.text};
  padding: 0 2vw;
  @media (max-width: 768px) {
    border-right: 0px solid ${({ theme }) => theme.text};
    border-left: 0px solid ${({ theme }) => theme.text};
  }
`
const ImgWrapper = styled.div`
  width: 20vw;
  display: block;
  @media (max-width: 768px) {
    width: 80vw;
  }
`
const ImgWrapperThaiSilver = styled.div`
  width: 15vw;
  display: block;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 70vw;
  }
`
const ImgWrapperTgjta = styled.div`
  width: 14vw;
  display: block;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 70vw;
  }
`
