import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd14 = () => (
  <StaticImage
    src="../../../images/Prod/advance-manufacturers-jewelry-team.png"
    alt="advance manufacturers jewelry team"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd14
