import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd10 = () => (
  <StaticImage
    src="../../../images/Prod/enamel-jewelry-factory-manufacturers.png"
    alt="Enamel jewelry factory manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd10
