import React from "react"
import { Flex, Box } from "rebass"

import styled from "styled-components"
import GreenH2 from "../../components/Typography/H2/GreenH2"
import P from "../../components/Typography/P/P"
import WrapperContainers from "../../components/WrapperContainer"
const CenterGreen = styled(GreenH2)`
  text-align: center;
`
const Production = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1]} p={[3, 2, 4]}>
        <CenterGreen>
          Jewelry Manufacturing at Advance Manufacturers{" "}
        </CenterGreen>

        <WrapPara>
          <P>
            From concept to finished piece, Advance Manufacturers is a one stop
            full service manufacturer of fine jewelry.  Integrating all
            processes of manufacturing under one roof, we guarantee efficiency,
            speed, high quality and exclusivity.  Our customers are always
            welcome to visit our factory to learn more about our ever evolving
            manufacturing process.
          </P>
        </WrapPara>
      </Box>
    </Flex>
  </Section>
)
export default Production

const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const WrapPara = styled.div`
  padding: 0 10vw;
  text-align: center;
`
