import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd15 = () => (
  <StaticImage
    src="../../../images/Prod/it-department-jewelry-software.png"
    alt="advance manufacturers jewelry It department software"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd15
