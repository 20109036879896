import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd5 = () => (
  <StaticImage
    src="../../../images/Prod/rubber-silicon-mould-making.png"
    alt="Rubber silicone mould making advance manufacturer"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd5
