import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd7 = () => (
  <StaticImage
    src="../../../images/Prod/filing-polishing.png"
    alt="jewelry filing polishing advance manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd7
