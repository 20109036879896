import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const Tgjta = () => (
  <StaticImage
    src="../../../images/TGJTA-Logo.svg"
    alt="Thai gen and jewelry traders Association"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default Tgjta
