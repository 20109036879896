import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd6 = () => (
  <StaticImage
    src="../../../images/Prod/laser-assembly-work-soldering.png"
    alt="Laser assembly work soldering advance manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd6
