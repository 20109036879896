import React from "react"
import { Flex, Box } from "rebass"

import styled from "styled-components"
import GreenH2 from "../../components/Typography/H2/GreenH2"
import P from "../../components/Typography/P/P"
import WrapperContainers from "../../components/WrapperContainer"
import ImgProd1 from "./Img/ImgProd1"
import ImgProd10 from "./Img/ImgProd10"
import ImgProd11 from "./Img/ImgProd11"
import ImgProd12 from "./Img/ImgProd12"
import ImgProd13 from "./Img/ImgProd13"
import ImgProd14 from "./Img/ImgProd14"
import ImgProd2 from "./Img/ImgProd2"
import ImgProd3 from "./Img/ImgProd3"
import ImgProd4 from "./Img/ImgProd4"
import ImgProd5 from "./Img/ImgProd5"
import ImgProd6 from "./Img/ImgProd6"
import ImgProd7 from "./Img/ImgProd7"
import ImgProd8 from "./Img/ImgProd8"
import ImgProd9 from "./Img/ImgProd9"
import LeftImg from "../../images/Prod/Ellipse-left.svg"
import RightImg from "../../images/Prod/Ellipse-right.svg"
import ImgProd15 from "./Img/ImgProd15"
const ProdStep1 = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[2, 2, 1]}>
        <ImgProd1 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[1, 1, 2]}>
        <GreenH2>Product Development &amp; Design</GreenH2>

        <P>
          Our product development team works with the designers to identify new
          trends in lifestyle, jewelry &amp; fashion. This research along with
          feedback and suggestions of our customers are integrated into the
          development of our own collection.
        </P>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[1, 2, 4]} order={[3, 3, 3]}>
        <GreenH2>CAD Technology and Rapid Prototyping</GreenH2>

        <P>
          The CAD designer role completes a crucial segment in our production
          process by preparing the master models for manufacturing and using the
          latest technologies in 3D wax printing.
        </P>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[1, 2, 4]} order={[4, 4, 4]}>
        <ImgProd2 />
        <Relative>
          <Left />
        </Relative>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[6, 6, 5]}>
        <ImgProd3 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[5, 5, 6]}>
        <GreenH2>Model Making</GreenH2>

        <P>
          While we use 3D CAD models for most of our models, we  maintain a team
          of  experienced model-makers who work on designs that cannot be
          created in CAD.
        </P>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[7, 7, 7]}>
        <GreenH2>Casting </GreenH2>

        <P>We use state of the art vacuum casting technology.</P>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[8, 8, 8]}>
        <ImgProd4 />
        <Relative>
          <Left />
        </Relative>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[10, 10, 9]}>
        <ImgProd5 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[9, 9, 10]}>
        <GreenH2>Wax Injection</GreenH2>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[11, 11, 11]}>
        <GreenH2>Laser, Assembly work &amp; Soldering</GreenH2>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[12, 12, 12]}>
        <ImgProd6 />
        <Relative>
          <Left />
        </Relative>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[14, 14, 13]}>
        <ImgProd7 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[13, 13, 14]}>
        <GreenH2>Filing &amp; Polishing</GreenH2>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[15, 15, 15]}>
        <GreenH2>Setting</GreenH2>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[16, 16, 16]}>
        <ImgProd8 />
        <Relative>
          <Left />
        </Relative>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[18, 18, 17]}>
        <ImgProd9 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[17, 17, 18]}>
        <GreenH2>Plating </GreenH2>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[19, 19, 19]}>
        <GreenH2>Enamel &amp; Resin </GreenH2>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[20, 20, 20]}>
        <ImgProd10 />
        <Relative>
          <Left />
        </Relative>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[22, 22, 21]}>
        <ImgProd11 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[21, 21, 22]}>
        <GreenH2>Quality Control </GreenH2>

        <P>
          Quality control isn't just a production step. It is a philosophy, and
          therefore needs to be taken care of in each step of the production,
          with the right approach and attitude.
        </P>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[23, 23, 23]}>
        <GreenH2>Packaging</GreenH2>

        <P>
          Additional to the standard packaging, we also are able to serve
          clients special packaging needs, including supplying cards and boxes.
          We also focus on using environmentally friendly material.
        </P>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[24, 24, 24]}>
        <ImgProd12 />
        <Relative>
          <Left />
        </Relative>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[26, 26, 25]}>
        <ImgProd13 />
        <Relative>
          <Right />
        </Relative>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[25, 25, 26]}>
        <GreenH2>Metal X-Ray</GreenH2>

        <P>
          To ensure quality standards are met in regards to alloy content and
          plating thicknesses, we use state of the art machinery in order to
          control these parameters.
        </P>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[27, 27, 27]}>
        <GreenH2>Sales &amp; Customer Service</GreenH2>

        <P>
          Our customer service team is the heart of our company.  Your sales rep
          does not only help process your designs and production orders, they
          also work diligently with the production team to ensure that your
          designs come out how you like them and that your deliveries are on
          time.
        </P>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[28, 28, 28]}>
        <ImgProd14 />
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[26, 26, 25]}>
        <ImgProd15 />
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 4]} order={[25, 25, 26]}>
        <GreenH2>IT &amp; Software Engineering</GreenH2>

        <P>
          With an ever evolving demand of production processes, manufacturing
          technlogies and customer requirements, one of the hearts of our
          operation is a highly skilled and talented IT team. They are
          responsible for coding the programs our systems run on, as well
          creating apps that we use to simplify and coordinate all tasks in the
          factory.
        </P>
      </Box>
    </Flex>
  </Section>
)
export default ProdStep1

const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const Left = styled(LeftImg)`
  width: 24vw;
  position: absolute;
  top: -20px;
  left: -30px;
  @media (max-width: 768px) {
    display: none;
  }
`
const Right = styled(RightImg)`
  width: 24vw;
  position: absolute;
  top: -20px;
  right: -30px;
  @media (max-width: 768px) {
    display: none;
  }
`
const Relative = styled.div`
  position: relative;
  padding: 50px 0;
`
