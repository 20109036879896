import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd13 = () => (
  <StaticImage
    src="../../../images/Prod/x-ray-jewelry-check-content.png"
    alt="x ray jewelry check content jewelry"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd13
