import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const Ditp = () => (
  <StaticImage
    src="../../../images/Prod/Ditp.png"
    alt="A dinosaur"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default Ditp
