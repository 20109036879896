import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StyledProductionHero from "../containers/ProductionProcess/Hero/ProductionHero"
import ProductionEnd from "../containers/ProductionProcess/ProdEnd"
import ProdStep1 from "../containers/ProductionProcess/ProdStep1"
import Prod from "../containers/ProductionProcess/Prod"

const ProductionProcess = () => (
  <Layout>
    <Seo title="Production Process jewelry manufacturer" />
    <StyledProductionHero />
    <Prod />
    <ProdStep1 />
    <ProductionEnd />
  </Layout>
)

export default ProductionProcess
