import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd11 = () => (
  <StaticImage
    src="../../../images/Prod/qality-control-jewelry-manufacturers.png"
    alt="qality control jewelry manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd11
