import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd4 = () => (
  <StaticImage
    src="../../../images/Prod//casting-jewelry-vacuum-technology.png"
    alt="Casting jewelry vacuum technology"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd4
