import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ThaiSilver = () => (
  <StaticImage
    src="../../../images/Prod/Thai-Silver.png"
    alt="A dinosaur"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ThaiSilver
