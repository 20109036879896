import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd9 = () => (
  <StaticImage
    src="../../../images/Prod/plating-department.png"
    alt="plating department jewelry manufacturer"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd9
