import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const ImgProd8 = () => (
  <StaticImage
    src="../../../images/Prod/setting-stone-jewelry.png"
    alt="setting stone jewelry"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default ImgProd8
